.global-banner {
  position: relative;
  min-height: 420px;
  background-color: #1c1a17;
  overflow: hidden;
  transition-property: height;
  transition-duration: 0.3s;
  animation-timing-function: ease-in;
}

@keyframes global-banner-item-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.global-banner-item {
  opacity: 1;
  height: 100%;
  display: block;
  position: absolute;
  width: 100%;
  background-size: cover;
  background-position: center center;
  animation-name: global-banner-item-animation;
  animation-duration: 2s;
}

.home-banner-item {
  // background-image: url('../../assets/images/banner_home.png');
  background-size: auto;
  // background-image: url('../../assets/images/banner_home_up.png'), url('../../assets/images/banner_home_down.png');
  // background-image: url('../../assets/images/banner_home_content.png'), url('../../assets/images/banner_home_bg.png');
  background-image: url('../../assets/images/banner_home_content_v3.png'), url('../../assets/images/banner_home_down.png');
}

// 这个是默认的 banner 背景图
.enrollment-banner-item {
  background-image: url('../../assets/images/banner_enrollment_demo.png');
}

.banner_enrollment {
  background-image: url('../../assets/images/banner_enrollment.png');
}

.banner_enrollment_demo {
  background-image: url('../../assets/images/banner_enrollment_demo.png');
}

.application-banner-item {
  background-image: url('../../assets/images/banner_app.png');
}
