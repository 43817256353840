.announcement {
  max-width: 800px;
  .anticon {
    &::before {
      display: none;
    }
  }

  .ant-confirm-title {
    text-align: center;
  }

  
  &.ant-confirm {
    .ant-modal-close, .ant-modal-header {
      display: block;
    }
    .ant-confirm-btns {
      display: flex;
      justify-content: center;
      float: none;
    }
  }
}

.announcement-content {
  margin-top: 10px;

  .content {
    margin-top: 20px;
    min-height: 300px;
    max-height: 600px;
    overflow: auto;
  }
}

.announcement-cell {
  margin-top: 10px;
}