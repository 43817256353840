.enrollment-page-section {
  padding-top: 16px;
  padding-bottom: 114px;
  background-color: #f7f7f7;
}
.enrollment-page-card-container {
  padding: 0 5px;
}
.enrollment-page-card {
  // padding-left: 144px;
  display: inline-block;
  // width: 360px;
  width: 100%;
  height: 116px;
  text-align: right;
  font-size: 22px;
  font-weight: 400;
  line-height: 116px;
  color: #fff!important;
  background-repeat: no-repeat;
  &.enrollment-kde {
    background-image: url('../../assets/images/enrollment_kde.png');
    background-color: #5bc3d1;
    background-position: 20px 20px;
  }
  &.enrollment-edu {
    background-image: url('../../assets/images/enrollment_edu.png');
    background-color: #63c47b;
    background-position: 10px 10px;
  }
  &.enrollment-hse {
    background-image: url('../../assets/images/enrollment_hse.png');
    background-color: #ffc445;
    background-position: 12px 14px;
  }
  i {
    margin: 0 30px 0 15px;
    font-size: 30px;
  }
}
.enrollment-page-container {
  margin-right: -22px;
}
@media (max-width: 1200px) {
  .enrollment-page-container {
    margin-right: -10px;
  }
}
@media (max-width: 992px) {
  .enrollment-page-container {
    margin-right: 0;
  }
}
.enrollment-page-card-list {
  margin: 0 -5px 16px -5px;
  // margin-bottom: 16px;
}
.enrollment-page-left-card-list {
  margin-left: 15px;
  margin-bottom: 16px;
  padding: 40px 0;
  height: 320px;
  background-color: #fff;
}
.enrollment-page-left-card {
  margin-bottom: 40px;
  padding: 0 35px;
  display: block;
  text-decoration: none;
  border-left: 2px solid transparent;
  &:focus {
    text-decoration: none;
  }
  h3 {
    font-size: 14px;
    cursor: pointer;
  }
  &:hover, &:active, &.active {
    border-color: #3398dc;
    h3 {
      color: #3398dc;
    }
  }
}
.enrollment-page-right-container {
  margin: 0 15px;
  padding: 0 32px;
  flex: 0 0 78%;
  max-width: 78%;
  min-height: 460px;
  background-color: #fff;
}
@media (max-width: 992px) {
  .enrollment-page-left-card-list, .enrollment-page-right-container {
    margin-right: 10px;
    flex: auto;
    max-width: none;
  }
}
.enrollment-msg-container {
  padding: 10px 0;
}
.enrollment-msg-item {
  padding: 30px 0;
  border-bottom: 1px solid #e9e9e9;
  &:last-of-type {
    border-color: transparent;
  }
}
.enrollment-msg-item-title, .enrollment-msg-item-sub-title, .enrollment-msg-item-content {
  font-weight: normal;
}
.enrollment-msg-item-title {
  font-size: 18px;
}
.enrollment-msg-item-sub-title {
  margin: 10px 0;
  font-size: 12px;
}
.enrollment-msg-item-content {
  font-size: 14px;
}
.enrollment-policy-container, .enrollment-admission-container {
  padding-bottom: 20px;
}
.enrollment-policy-header {
  margin-top: 20px;
}
.enrollment-policy-header>div {
  margin-right: -80px;
  margin-bottom: 20px;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .enrollment-policy-header>div {
    margin-right: -60px;
  }
}
.enrollment-policy-header-search-name {
  font-size: 12px;
  font-weight: 400;
}
.enrollment-policy-header-search-form {
  margin-left: 8px;
  padding: 10px;
  display: inline-block;
  max-width: 210px;
  width: 100%;
  height: 30px;
  border: 1px solid #dbe1e8;
  border-radius: 4px;
  font-size: 12px;
  visibility: hidden;
  -webkit-appearance: menulist;
  &>div, &>span {
    width: 100%;
    visibility: visible;
  }
}
.enrollment-policy-header-search-btn {
  display: inline-block;
  width: 74px;
  height: 28px;
  border-radius: 4px;
  text-align: center;
  font-size: 12px;
  line-height: 28px;
  // color: #fff;
  // background-color: #108ee9;
}
.enrollment-policy-item {
  padding: 30px 0;
  border-bottom: 1px solid #e9e9e9;
  &:last-of-type {
    border-color: transparent;
  }
}
.enrollment-policy-item-title, .enrollment-policy-item-sub-title, .enrollment-policy-item-content {
  font-weight: normal;
}
.enrollment-policy-item-title {
  font-size: 18px;
}
.enrollment-policy-item-sub-title {
  margin: 10px 0;
  font-size: 12px;
}
.enrollment-policy-item-content {
  font-size: 14px;
  white-space: pre-line;
}
.enrollment-policy-item-btn {
  margin-top: 25px;
  display: inline-block;
  width: 74px;
  height: 28px;
  border-radius: 4px;
  text-align: center;
  font-size: 12px;
  line-height: 28px;
  color: #fff;
  background-color: #108ee9;
  transition: all .3s;
  &:focus, &:hover {
    color: #fff;
    background-color: #40a9ff;
    border-color: #40a9ff;
  }
  &:active {
    color: #fff;
    background-color: #096dd9;
    border-color: #096dd9;
  }
}
// 分页
.enrollment-pagination {
  margin-top: 25px;
}
.enrollment-download-container {
  margin-right: -15px;
  padding: 40px 0;
}
.enrollment-download-item {
  margin: 0 15px 25px 0;
  padding: 0 24px;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  float: left;
  max-width: 265px;
  width: 265px;
  height: 90px;
  border-radius: 6px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
  transition: background-color .4s;
  cursor: pointer;
  &:hover {
    background-color: #f3ffe2;
  }
  &.enrollment-area:hover {
    background-color: #f3ffe2;
  }
  &.enrollment-policy:hover {
    background-color: #eef5ff;
  }
  &.enrollment-sign:hover {
    background-color: #fff6e3;
  }
  img {
    height: 30px;
  }
}
.enrollment-download-item-right-title {
  font-size: 16px;
}
.enrollment-download-item-right-descs {
  font-size: 12px;
}

.enrollment-page-left-card-content {
  margin-bottom: 22px;
  padding: 0 6px;
}
.enrollment-page-left-card-content-item {
  margin-bottom: 34px;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 22px 12px;
  color: #fff;
  transition: .2s ease;
  text-decoration: none;
  text-align: center;
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 32px;
  &:hover {
    color: #fff;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .enrollment-page-left-card-content-item {
    font-size: 20px;
  }
}
.enrollment-page-left-card-content-btn-green {
  background-color: #72c02c;
  &:hover, &:focus, &:active {
    background-color: #8cd54a;
    border-color: #8cd54a;
  }
}
.enrollment-page-left-card-content-btn-blue {
  background-color: #3398dc;
  &:hover, &:focus, &:active {
    background-color: #5faee3;
    border-color: #5faee3;
  }
}
.enrollment-page-left-card-content-btn-cyan {
  background-color: #00bed6;
  &:hover, &:focus, &:active {
    background-color: #0ae4ff;
    border-color: #0ae4ff;
  }
}
.enrollment-page-left-card-content-btn-teal {
  background-color: #18ba9b;
  &:hover, &:focus, &:active {
    background-color: #22e3be;
    border-color: #22e3be;
  }
}
// right
.enrollment-page-right-nav {
  border-bottom: 2px solid #72c02c;
  margin-bottom: 22px;
  list-style: none;
  display: flex;
  padding: 0;
  font-weight: normal;
  li {
    flex-grow: 1;
    text-align: center;
    flex-basis: 0;
  }
}
.enrollment-page-right-nav-a {
  color: #666;
  transition-property: color, background-color, border-color;
  transition-duration: .2s;
  transition-timing-function: ease-in;
  padding: 12px 0;
  display: block;
  font-size: 14px;
  &.active {
    color: #fff;
    background-color: #72c02c;
  }
}
// tab1
.enrollment-page-right-tab {
  display: none;
  padding: 0 6px;
  &.active {
    display: block;
  }
}
.enrollment-page-right-tab-article-head {
  margin-bottom: 18px;
  h2 {
    font-weight: 300;
    font-size: 28px;
    line-height: 1.4;
  }
  a {
    color: #111;
    text-decoration: none;
    transition: all .2s;
    &:hover {
      color: #72c02c;
    }
  }
}
.enrollment-page-right-tab-article-main {
  margin-bottom: 18px;
  color: #555;
  font-weight: normal;
  font-size: 18px;
}
.enrollment-page-right-tab-article-foot {
  display: flex;
  span {
    color: #999;
    font-size: 14px;
    font-weight: 300;
  }
}
.enrollment-page-right-tab-article-hr {
  margin: 46px 0;
  border-top: 1px solid #eee;
}
// tab2
.enrollment-page-right-tab-2-ul {
  flex-direction: column;
  padding-left: 0;
  list-style: none;
}
.enrollment-page-right-tab-2-ul-a {
  display: block;
  margin: -1px 0 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  color: #666;
  padding: 8px 16px;
  &.active {
    color: #72c02c;
  }
}
.enrollment-page-right-tab-2-content {
  display: none;
  color: #000;
  &.active {
    display: block;
  }
  h4 {
    text-align: center;
  }
}
// tab3
.enrollment-page-right-tab-3-title {
  color: #03a9f4;
  line-height: 1.4;
  font-size: 24px;
}
.enrollment-page-right-tab-3-hr {
  margin: 32px 0;
  border-top: 1px solid #eee;
}
.enrollment-page-right-tab-3-content-a {
  margin: 0 12px 18px 0;
  padding: 14px 30px;
  line-height: 1.4;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.6px;
  transition: .2s ease;
  text-align: left;
  border: 1px solid transparent;
  vertical-align: middle;
  display: inline-block;
  i {
    float: right;
    font-size: 36px;
    margin-left: 18px;
  }
}
.enrollment-page-right-tab-3-content-btn-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
  cursor: pointer;
  &:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  &:active {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    box-shadow: 0 0 0 2px rgba(220, 53, 69, .5);
  }
}
.enrollment-page-right-tab-3-content-btn-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
  cursor: pointer;
  &:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  &:active {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
    box-shadow: 0 0 0 2px rgba(40, 167, 69, .5);
  }
}
.enrollment-page-right-tab-3-content-btn-primary {
  color: #72c02c;
  background-color: transparent;
  background-image: none;
  border-color: #72c02c;
  cursor: pointer;
  &:hover {
    color: #fff;
    background-color: #6db82a;
    border-color: #6db82a;
  }
  &:active {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, .5);
  }
}
.enrollment-page-right-tab-3-content-a-span {
  float: left;
  span {
    font-size: 12px;
    display: block;
  }
}
// tab4
.enrollment-page-right-tab-4-title {
  text-align: center;
  padding-top: 60px;
  padding-bottom: 20px;
}
.enrollment-page-right-tab-4-title-head {
  color: #000;
  font-weight: 600;
  margin-bottom: 70px;
  font-size: 58px;
}
.enrollment-page-right-tab-4-title-nav {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  li {
    border-right: 1px solid #eee;
    padding: 0 24px;
    display: inline-block;
  }
  li:last-child {
    border-right: 1px solid transparent;
  }
}
.enrollment-page-right-tab-4-title-nav-a {
  color: #999;
  display: inline-block;
  font-size: 18px;
  &:hover, &:focus {
    text-decoration: none;
    color: #999;
  }
}
.enrollment-page-right-tab-4-content {
  padding: 0 16px 114px 16px;;
}
.enrollment-page-right-tab-4-content-item {
  box-shadow: 0 0 2px #ccc;
  border-radius: 4px;
  padding: 32px 0;
  margin-bottom: 114px;
}
.enrollment-page-right-tab-4-content-item-row {
  justify-content: center;
}
.enrollment-page-right-tab-4-content-item-head {
  color: #03a9f4;
  text-align: center;
  margin-bottom: 68px;
  h2 {
    font-size: 32px;
    font-weight: 600;
  }
}
.enrollment-page-right-tab-4-content-item-card {
  margin-bottom: 22px;
}
.enrollment-page-right-tab-4-content-item-card-head {
  border-bottom: 1px solid #eee;
}
.enrollment-page-right-tab-4-content-item-card-head-a {
  padding: 16px 34px;
  color: #555;
  display: flex;
  justify-content: space-between;
  &:hover, &:focus {
    color: #555;
  }
}
.enrollment-page-right-tab-4-content-item-card-main {
  padding: 34px;
  color: #777;
}
.enrollment-page-right-tab-4-content-item-card-question {
  font-size: 22px;
  margin-bottom: 16px;
  padding: 12px 20px;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  color: #721c24;
  background-color: #f8d7da;
  text-align: center;
}

// 弹窗
.enrollment-mask {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, .7);
}
.enrollment-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 256px;
  transform: translate(-50%, -50%);
  z-index: 1000;
  text-align: center;
  background-color: #fff;
  hr {
    margin: 20px 0 0 0;
    color: #e5e5e5;
  }
}
.enrollment-modal-title {
  margin: 20px 0 16px 0;
}
.enrollment-modal-content {
  margin: 0 20px;
  font-size: 14px;
  font-weight: normal;
}
.enrollment-modal-btn {
  margin: 10px 0;
  display: inline-block;
  width: 70px;
  height: 32px;
  border-radius: 4px;
  font-size: 14px;
  background-color: #3398dc;
}

.user-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, .45);
}
.user-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  z-index: 1000;
  width: 520px;
  height: 184px;
  border-radius: 4px;
  background-color: #fff;
}
.user-mask {
  display: none;
}
.user-modal-header {
  margin-bottom: 44px;
  font-size: 14px;
  font-weight: 400;
}
.user-modal-content {
  padding: 0 32px;
  display: flex;
  justify-content: space-around;
  a {
    padding-left: 78px;
    display: inline-block;
    width: 190px;
    height: 66px;
    line-height: 66px;
    text-align: center;
    color: #fff;
    background-repeat: no-repeat;
    i {
      margin-left: 8px;
    }
  }
}
.user-close {
  float: right;
  cursor: pointer;
}
.user-modal-primary {
  background-image: url('../../assets/images/primary.png');
}
.user-modal-junior {
  background-image: url('../../assets/images/junior.png');
}
.js-enrollment-progress-none {
  display: none;
}
