* {
  position: relative;
  width: auto;
  box-sizing: border-box;
  outline: 0 !important;
}

html {
  display: block;
  margin: auto;
  width: 100%;
  font-size: $root-base;
  font-family: $font-family-normal;
}

body {
  position: relative;
  width: 100%;
  // min-width: $base-width;
  height: 100%;
  margin: 0 auto;
  font-size: $font-size-base;
  font-family: $font-family-normal;
  font-weight: 300;
  // background-color: $body-bgc;
  color: $text-color-normal;
}

a {
  text-decoration: none;
  color: $link-color;

  &:hover,
  &:active {
    text-decoration: none;
    color: $link-color-hover;
  }
}

img {
  display: block;
  max-width: 100%;
  border: 0;
}

input, select{
  -webkit-appearance:none;
}

ul, h1, h2, h3, h4, h5, h6, label, p {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ul {
  list-style: none;
}

label {
  display: block;
}

.disabled, [disabled] {
  &, * {
    cursor: not-allowed!important;
  }
}
