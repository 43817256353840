.enrollment-m-body {
  .enrollment-page-section {
    padding: 0;
  }
  .container {
    padding: 0;
  }
  .enrollment-page-left-card-list {
    height: auto;
    padding: 0;
    margin: 0;
    margin-bottom: -1px;
    position: relative;
    border-bottom: 10px solid #f2f2f2;
    .enrollment-page-left-card {
      position: relative;
      z-index: 10;
      margin-bottom: 0px;
      width: 50%;
      border-left: 0;
      h3 {
        line-height: 21px;
        font-size: 14px;
        padding: 12px;
      }
      text-align: center;
      display: inline-block;
      &.active {
        border-left: 0;
        border-bottom-width: 2px;
        border-bottom-style: solid;
      }
    }
  }
  .enrollment-page-right-container {
    margin: 0;
    padding: 0;
    max-width: 100%;
  }

  .enrollment-policy-header {
    margin-top: 0;
    padding: 4px 32px 10px;
    > div {
      margin-bottom: 4px;
    }
    border-bottom: 10px solid #f2f2f2;
  }
  .enrollment-policy-header-search-btn {
    margin-top: 11px;
  }
  .enrollment-policy-main {
    padding: 4px 32px 10px;
  }
  .enrollment-pagination {
    padding: 4px 32px 10px;
  }
  .row {
    margin: 0;
  }
}
