.navbar-toggler {
  position: absolute;
  right: 0;
  top: 12px;
}
.header-nav {
  position: relative;
  margin-left: auto;
  height: 100%;
}
.header-nav-item {
  display: inline-flex;
  align-items: flex-end;
  margin: 10px;
  @media (min-width: 992px) {
    margin: 0 10px;
    // margin-left: 10px;
    // margin-right: 10px;
  }
  @media (min-width: 1200px) {
    margin: 0 15px;
    // margin-left: 15px;
    // margin-right: 15px;
  }
  &.active {
    .header-nav-item-link {
      height: auto!important;
      border-bottom: 2px #03A9F4 solid;
    }
  }
}

.header-nav-item-link {
  padding: 0;
  font-size: 14px;
  @media (min-width: 992px) {
    padding: 18px 0 22px;
  }
}

.header-total-nav {
  // padding: 6px 0;
  padding: 0;
  height: 100%;
}
.header-container {
  height: 100%;

  .navbar-collapse {
    height: 100%;
  }
}
.header-total-nav-logo-a {
  margin: 20px 0;
  padding: 0;

  img {
    display: inline-block;
    vertical-align: top;
  }
}

.new-header {
  // height: 74px;
  // line-height: 3;
  height: auto;
  min-height: 74px;
  @media (min-width: 992px) {
    height: 74px;
  }
  transition: all .5s;
  animation-timing-function: ease;
}

.home-normal-header, .immerse-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  // height: auto;
  // min-height: 74px;
  // @media (min-width: 992px) {
  //   height: 74px;
  // }
  // line-height: 3;
}

.btn-show-header {
  background-color: rgba(0, 0, 0, .5);
}

.home-normal-header {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .1);
  background-color: #fff;
}

.immerse-header {
  color: #fff;
  a {
    color: #fff;
  }
  .header-nav-item.active .header-nav-item-link{
    border-bottom: 2px solid #fff;
  }
}

.normal-title,
.immerse-title {
  margin-left: 8px;
  display: inline-block;
  vertical-align: middle;

  p {
    line-height: 1.5;
  }
  
  .header-total-nav-logo-title-department {
    font-size: 12px;
  }
}

.normal-title {
  color: #0C7AE7;
}

.immerse-title {
  color: #fff;
}

.normal-title-text,
.immerse-title-text {
  line-height: 1;

  img {
    height: 20px;
  }
}

.header-need-login-nav-item {
  // min-width: 146px;
}

.header-auth-nav-item {
  visibility: hidden;
}

