.error-page-containt {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 326px;
  height: 430px;
}

.error-page-img {
  display: block;
  width: 326px;
  height: 202px;
  margin-bottom: 24px;
  background: url('../images/error_page.png') no-repeat;
}

.error-page-text {
  color: #999999;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 24px;
}

#error-page-button {
  width: 100px;
  height: 32px;
  line-height: 32px;
  color: white;
  background: #3296FA;
  text-align: center;
  font-size: 14px;
  border-radius: 4px;
  margin: 0 auto;
  cursor: pointer;
}