
.mask {
  position: fixed;
  top: 0;
  width: 100%;

  &:before {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: ' ';
    background: #000;
    opacity: .4;
    z-index: 1200;
  }
}