@import "./public";
@import "./components/groups/header";
@import "./components/groups/footer";
@import "./components/groups/banner";
@import "./components/groups/login";

@import "./components/page/index";
@import "./components/page/application";
@import "./components/page/enrollment";
@import "./components/page/enrollment_m";
@import "./components/page/user";
@import "./components/page/announcement";
@import "./components/page/braft_output_content";
@import "./components/page/system_error";

.hide {
  display: none!important;
}

.require-label::before {
  content: '*';
  display: inline;
  color: red;
  text-align: center;
  line-height: 36px;
  margin-right: 2px;
}

.disabled, [disabled] {
  &, * {
    cursor: not-allowed!important;
  }
}
