$btn-color: #3398DC;
.user-body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f7f7f7;
  &>header {
    background-color: #fff;
  }
}
.user-section {
  padding: 18px 0 100px 0;
  flex-grow: 1;
  background-color: #f7f7f7;
}
.user-sidebar-left {
  margin-bottom: 50px;
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
  padding: 0;
  background-color: #fff;
}
.user-sidebar-left-title {
  margin: 0 auto;
  padding: 36px 0 32px 0;
  width: 124px;
  border-bottom: 1px solid #ebebeb;
}
.user-sidebar-left-title-username {
  margin-bottom: 14px;
  font-size: 16px;
  color: #262626;
  i {
    margin-right: 10px;
  }
}
.user-sidebar-left-title-identity {
  margin-left: 24px;
  position: relative;
  height: 16px;
  font-size: 12px;
  color: #878787;
  white-space: pre-wrap;
  overflow: hidden;

  .user-sidebar-left-title-identity-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
}
.user-sidebar-left-list {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
}
.user-sidebar-left-list-item {
  display: block;
  margin-top: 36px;
  padding-left: 32px;
  border-left: 2px solid transparent;
  cursor: pointer;
  &:hover, &:focus, &:active, &.active {
    border-left-color: $btn-color;
    color: #2a84d3;
  }
  &.disabled {
    border-left-color: transparent;
    color: inherit;
    cursor: not-allowed;
  }
}
.user-sidebar-right {
  margin: 0 12px;
  padding: 0;
  min-height: 402px;
  font-size: 14px;
  font-weight: 400;
  background-color: #fff;
}
.user-sidebar-right-app-row {
  margin: 35px 30px;
}
.user-sidebar-right-app-title {
  margin-bottom: 28px;
  padding-left: 14px;
  border-left: 4px solid #03A9F4;
}
.user-right-group-pwd, .user-right-group-setting-item, .user-right-group-tel-progress-form-group, .user-right-group-name, .user-right-group-email {
  display: flex;
  margin: 18px 0 14px 36px;
  label {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    width: 76px;
  }
}
.user-pwd-desc {
  margin-left: 112px;
  margin-top: -14px;
  color: #999999;
}
.user-right-group-pwd:first-of-type, .user-right-group-setting-item:first-of-type {
  margin-top: 40px;
}
.user-right-group-pwd-input, .user-right-group-setting-item-input, .user-right-group-tel-progress-form-group-input, .user-right-group-name-input, .user-right-group-email-input {
  padding: 6px 10px;
  width: 302px;
  height: 36px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color ease-in-out .15s;
  &:focus {
    border-color: #72c02c;
    box-shadow: none;
  }
}
.user-right-group-pwd-btn-group, .user-right-group-setting-item-btn-group, .user-right-group-tel-progress-form-group-btn-group, .user-right-group-email-btn-group, .user-right-group-name-btn-group {
  padding-left: 110px;
  margin-top: 28px;
}
.user-right-group-pwd-btn-save, .user-right-group-setting-item-btn-save, .user-right-group-tel-progress-form-group-btn-next, .user-right-group-email-btn-save, .user-right-group-name-btn-save {
  margin-right: 10px;
  padding: 6px 10px;
  width: 68px;
  border: 1px solid transparent;
  border-radius: 4px;
  line-height: 1.5;
  background: #108ee9;
  color: #fff;
  transition: .2s ease;
  cursor: pointer;
}
.user-right-group-pwd-btn-cancel, .user-right-group-setting-item-btn-cancel, .user-right-group-tel-progress-form-group-btn-cancel, .user-right-group-email-btn-cancel, .user-right-group-name-btn-cancel {
  margin-right: 10px;
  padding: 6px 10px;
  width: 68px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  line-height: 1.5;
  background-color: #fff;
  transition: .2s ease;
  cursor: pointer;
}
.user-right-group-msg-item {
  margin: 0 30px;
  padding: 20px 0;
  border-bottom: 1px solid #ebebeb;
}
.user-right-group-msg-item-title {
  margin-right: 10px;
}
.user-right-group-msg-item-time {
  float: right;
}
.user-right-group-data-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 32px;
  border-bottom: 1px solid #ebebeb;
  font-size: 16px;
  color: #262626;
  line-height: 26px;
  a {
    font-size: 14px;
    color: #2a84d3;
  }
}
.school-right-group-school-head {
  padding: 18px 32px;
  border-bottom: 1px solid #ebebeb;
  font-size: 16px;
  font-weight: bold;
  color: #262626;
  line-height: 26px;
}
.user-right-group-data-head-update-pwd {
  padding-left: 20px;
}
.user-right-group-data-head-update-data {
  padding-right: 20px;
  border-right: 1px solid #ebebeb;
  &.disabled {
    color: inherit;
    cursor: not-allowed;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray;
  }
}
.user-right-group-data-item {
  margin: 0 30px;
  padding: 25px 0;
  border-bottom: 1px solid #ebebeb;
}
.user-right-group-data-item:last-of-type {
  border-bottom: none;
}
.disabled>.user-right-group-data-item-img {
  color: inherit;
  cursor: not-allowed;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
}
.user-right-group-data-item-img {
  display: inline;
  vertical-align: middle;
  margin-right: 10px;
  &.disabled {
    color: inherit;
    cursor: not-allowed;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray;
  }
}
.user-socialites:first-of-type {
  margin-left: 0;
}
.user-socialites {
  margin: 0 10px 0 20px;
}
.user-right-group-data-item-wechat,
.user-right-group-data-item-qq {
  color: #03A9F4;
  cursor: pointer;
}
.disabled>.user-right-group-data-item-wechat,
.disabled>.user-right-group-data-item-qq {
  color: inherit;
  cursor: not-allowed;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
}
.user-right-group-data-item-title {
  margin-right: 20px;
  line-height: 1.6;
}
.user-right-group-data-item-update-tel {
  margin-left: 10px;
  font-size: 12px;
  color: #1396f0;
  &.disabled {
    color: inherit;
    cursor: not-allowed;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray;
  }
}
.user-right-group-setting {
  padding-top: 35px;
  select {
    appearance: menulist;
    -moz-appearance: menulist;
    -webkit-appearance: menulist;
  }
}
.user-right-group-tel {
  padding-top: 35px;
}
.user-right-group-tel-progress {
  margin: 0 0 40px 35px;
}
.user-right-group-tel-progress-num {
  display: inline-block;
  margin-right: 5px;
  width: 26px;
  height: 26px;
  border: 1px solid #bcbcbc;
  border-radius: 50%;
  text-align: center;
  line-height: 26px;
  color: #ccc;
  background-color: #fff;
  &.active {
    color: #fff;
    background-color: $btn-color;
  }
  &.success {
    border: 1px solid $btn-color;
    color: $btn-color;
  }
}
.user-right-group-tel-progress-text {
  color: #999;
  &.active {
    color: #666;
  }
}
.user-right-group-tel-progress-line {
  display: inline-block;
  margin: 4px 14px 4px 17px;
  width: 118px;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  &.active {
    border-top: 1px solid #108de9;
    border-bottom: 1px solid #108de9;
  }
}
.user-right-group-tel-code-group {
  display: inline-block;
  width: 302px;
}
.user-right-group-tel-code-input-group {
  display: flex;
  input {
    display: flex;
    padding: 6px 10px;
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    line-height: 1.5;
    color: #555;
    transition: border-color ease-in-out .15s;
    &:focus {
      border-color: #72c02c;
    }
  }
}
.user-right-group-tel-code-input-group-btn {
  display: flex;
}
.user-right-group-tel-code-btn-get-code, .user-right-group-email-code-btn-get-code {
  margin-left: -1px;
  border: 1px solid transparent;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 12px;
  line-height: 1.5;
  white-space: nowrap;
  color: #fff;
  background-color: $btn-color;
  // width: 76px;
  text-align: center;
  cursor: pointer;
}
.user-right-group-tel-success {
  padding-top: 35px;
}
.user-right-group-tel-success-main {
  margin-left: 220px;
  width: 196px;
  text-align: center;
  img {
    display: inline;
    padding-bottom: 26px;
  }
  p {
    margin-bottom: 14px;
  }
  a {
    color: #2a84d3;
  }
}
.js-right-group {
  display: none;
}

.user-application-page-content-item-container {
  padding: 0;
}
.user-application-page-content-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 24px 16px;
  border: 1px solid #eee;
  border-radius: 3.2px;
  font-weight: normal;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.10);
}
.user-application-page-content-item-img {
  width: 30px;
}
.user-application-page-content-item-name {
  flex-grow: 1;
  margin-left: 8px;
  font-size: 18px;
  font-weight: 400;
  color: #0D253E;
  // margin-bottom: 8px;
  // line-height: 1.4;
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
  .user-application-page-content-item {
    padding: 24px 8px;
  }
  .user-application-page-content-item-name {
    font-size: 14px;
  }
}
.user-application-page-content-item-link {
  width: 42px;
  border: 1px solid $btn-color;
  border-radius: 100px;
  font-size: 14px;
  text-align: center;
  color: $btn-color;
  transition: .2s ease;
  &:visited, &:hover, &:active, &:focus {
    color: $btn-color;
    text-decoration: none;
  }
  &:hover {
    color: #fff;
    background-color: $btn-color;
  }
}
.user-application-name {
  align-self: center;
  font-size: 14px;
  color: #36abe5;
}
.user-application-page-content-item-foot {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.user-application-page-content-item-foot-left {
  padding: 0;
  font-weight: normal;
  div {
    padding-top: 6px;
    color: #999;
  }
}
.user-application-page-content-item-foot-right {
  align-self: center;
  a {
    padding: 6px 10px;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    color: $btn-color;
    border-color: $btn-color;
    background-color: transparent;
    transition: .2s ease;
    &:hover {
      color: #fff;
      background-color: $btn-color;
    }
  }
}

.msg-badge {
  margin-left: 8px;
  // display: inline-block;
  height: 0;
  width: 0;
  border: 3px solid #f5222d;
  border-radius: 50%;
  transform: translateY(-50%);
  display: none;
}
.header-tab {
  margin: 0 12px;
  padding: 6px 14px;
  display: inline-block;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #666;
  background-color: #EFEFF4;
  cursor: pointer;
}
.header-tab:hover {
  color: #40a9ff;
}
.header-tab:active,
.header-tab.active {
  color: #fff;
  background-color: #03A9F4;
}
.message-header {
  font-size: 14px;
  color: #333;
}
.message-header-time-content {
  margin-right: 40px;
}
.message-header:hover,
.message-header:hover .message-header-time-content {
  color: #03A9F4;
}
.message-header-icon {
  font-size: 16px;
  vertical-align: middle;
  transition: transform .3s;
}
.ant-collapse-item-active .message-header-icon {
  transform: rotate(180deg);
}
.message-header-title {
  display: inline-block;
  width: 500px;
}
.message-header-time {
  float: right;
  font-size: 12px;
  color: #999;
}
.message-header-badge {
  float: left;
  height: 20px;
  width: 14px;
}
.message-content {
  font-size: 12px;
  color: #666;
}
.message-link {
  font-size: 12px;
  color: #40a9ff;
}
.message-link:hover,
.message-link:active {
  color: #1890ff;
}
.message-pagination {
  padding: 24px;
}
.no-data {
  padding: 12px 0 12px 40px;
}
.user-right-group-data-head-type {
  font-size: 14px;
  display: flex;
  line-height: 26px;
  margin-left: auto;
  align-items: center;
  position: relative;
}
.user-right-group-data-head-type-select-only {
  display: none;
  padding: 0 10px;
  width: 114px;
  height: 26px;
  text-align: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.user-right-group-data-head-type-select {
  width: 105px;
  height: 24px;
  font-size: 13px;
  border: 1px solid #7EBDFF;
  font-weight: normal;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background: #D2E7FD;
  color: #3296FA;
}
.user-right-group-data-head-type-text {
  width: 88px;
  line-height: 16px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-right-group-data-head-select-arrow {
  width: 9px;
  height: 6px;
  background: url('../images/arrow_down_green.png') no-repeat;
  background-size: 100% 100%;
}

.user-right-group-data-head-type-option {
  position: absolute;
  background: #fff;
  color: #333;
  top: 100px;
  width: 114px;
  box-shadow:0px 2px 6px 0px rgba(204,204,204,1);
  border-radius:4px;
  z-index: 10;
  padding: 8px 0px;
  display: none;
  transition: display 1s;
  .item {
    padding: 8px;
    line-height: 20px;
  }
  .active {
    background: #E7F3FF;
  }
}
.user-option-mask {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 10;
}
.school-right-group-school-content {
  padding: 0 30px;
  font-size: 16px;
  color: #333333;
  .sohool-right-group-info {
    border-bottom: 1px solid #ebebeb;
    padding: 23px 0 41px 0;
  }
  .school-right-group-qr-code {
    width: 148px;
    height: 148px;
    margin-top: 7px;
    background: url('../images/qr-code.png') no-repeat;
    background-size: 100%;
    margin-left: -6px;
  }
  .school-right-group-desc {
    padding-top: 23px;
  }
  .school-right-group-desc-title {
    color: #333333;
    margin-bottom: 16px;
  }
  .school-right-group-desc-item {
    position: relative;
    font-size: 14px;
    color: #999999;
    padding-left: 28px;
    margin-bottom: 8px;
    &::before {
      content: '';
      position: absolute;
      top: 4px;
      left: 0;
      width: 20px;
      height: 14px;
      background: url('../images/tips-item.png') no-repeat;
      background-size: 100%;
    }
  }
}