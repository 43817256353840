.application-page-content-section {
  background-color: #f8f8f8;
  padding-top: 28px;
  padding-bottom: 28px;
}
.application-page-content-head {
  padding-bottom: 10px;
  padding-top: 50px;
  margin-bottom: 16px;
  h2 {
    margin: 0;
    margin-bottom: 6px;
    line-height: 1.4;
    border-left: 4px solid #03a9f4;
    padding-left: 10px;
    font-size: 34px;
    font-weight: bold;
    color: #0d253e;
  }
}
.application-page-content-item {
  padding: 20px;
  margin-bottom: 14px;
  border-radius: 10px;
  border: 1px solid #eee;
  background-color: #fff;
  font-weight: normal;
}
.application-page-content-item-img {
  padding: 2px;
  margin-right: 10px;
  width: 40px;
  vertical-align: middle;
  display: inline-block;
}
.application-page-content-item-head {
  color: #000;
  font-weight: 200;
  font-size: 24px;
  margin: 0;
  margin-bottom: 8px;
  line-height: 1.4;
}
.application-page-content-item-main {
  color: #777;
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 14px;
}
.application-page-content-item-foot {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.application-page-content-item-foot-left {
  padding: 0;
  font-weight: normal;
  div {
    color: #999;
    padding-top: 6px;
  }
}
.application-page-content-item-foot-right {
  align-self: center;
  a {
    font-weight: 600;
    border-radius: 50px;
    color: #3398dc;
    border-color: #3398dc;
    background-color: transparent;
    transition: .2s ease;
    text-decoration: none;
    font-size: 14px;
    padding: 6px 10px;
    &:hover {
      color: #fff;
      background-color: #3398dc;
    }
  }
}