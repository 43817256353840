$em-base              : 16px;

$root-base            : $em-base;
$base-width           : 1000px;

/**
 * font Style
 */
$font-family-normal   : 'PingFang SC', 'Microsoft YaHei', '微软雅黑', 'Adobe 黑体Std R', sans-serif !default;
$font-size-base       : $em-base;


/**
 * body Style
 */

$body-width           : $base-width !default;
$body-bgc-white       : #fff !default;
$body-bgc             : #c2edff !default;

$head-bgc             : #3799ea !default;

/**
 * Action Style
 */

$text-color-white     : #fff !default;
$text-color-obvious   : #333 !default;
$text-color-normal    : #666 !default;
$text-color-mute      : #999 !default;

$link-color           : $text-color-normal !default;
$link-color-hover     : $text-color-normal !default;

$input-group-color    : #399ef3 !default;
$input-group-hover    : #1784E5 !default;


$border-color-normal  : #e5e5e5 !default;
